import "./App.css";

import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import {
  Routes,
  Route,
  useParams,
  Navigate,
  Link,
  useNavigate,
} from "react-router-dom";
import Loading from "./Loading";
import MarkdownViewer from "./MarkdownViewer";
import MarkdownEditor from "./MarkdownEditor";
import LatestWikiChanges from "./LatestWikiChanges";
import axios from "axios";
import { main_title, main_markdown } from "./pages/main.js";
import { about_title, about_markdown } from "./pages/about.js";
import { privacy_title, privacy_markdown } from "./pages/privacy.js";
import { terms_title, terms_markdown } from "./pages/terms.js";
import { a11y_title, a11y_markdown } from "./pages/a11y.js";
import { urlTitle, encodeTitle, normalizeTitle } from "./Title.js";
import PageHistory from "./PageHistory.js";
import Search from "./Search.js";
import PagesList from "./PagesList.js";

function InternalEditor() {
  const [markdown, setMarkdown] = useState("");

  const onChange = (content) => {
    setMarkdown(content);
  };

  return (
    <>
      <div className="sub-header">
        <h1>עורך פנימי</h1>
      </div>
      <p>אם הגעת לעמוד הזה - זה כנראה בטעות...</p>
      <div className="editor-container">
        <MarkdownEditor initMarkdown={markdown} onChange={onChange} />
      </div>
      <pre className="markdown">{markdown}</pre>
    </>
  );
}

function WikiPage() {
  const [loading, setLoading] = useState(true);
  const [markdown, setMarkdown] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [exists, setExists] = useState(false);
  const [modifiedMarkdown, setModifiedMarkdown] = useState("");

  const { title } = useParams();
  const navigate = useNavigate();

  const normalized_title = normalizeTitle(title);
  const url_title = normalized_title.replaceAll(" ", "_");

  useEffect(() => {
    if (url_title !== title) {
      navigate(`/wiki/${url_title}`, { replace: true });
      return;
    }

    document.title = `${normalized_title} | קהילה.טק`;
    axios
      .get(`/api/page/get/${encodeTitle(normalized_title)}`)
      .then((res) => {
        if (res.data.error) {
          setEditMode(true);
          setExists(false);
          setLoading(false);
        } else {
          setMarkdown(res.data.markdown);
          setExists(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        alert("שגיאה פנימית - נא נסו שנית");
      });
    // todo return method to cancel request
  }, [normalized_title, url_title, title, navigate]);

  const onChange = (md) => {
    setModifiedMarkdown(md);
  };

  const onSave = () => {
    setLoading(true);
    axios
      .post(`/api/page/edit/${encodeTitle(normalized_title)}`, {
        content: modifiedMarkdown,
      })
      .then(() => {
        setMarkdown(modifiedMarkdown);
        setEditMode(false);
        setLoading(false);
      })
      .catch((err) => alert(`Unable to save: ${err}`));
  };

  const onCancel = () => {
    setModifiedMarkdown("");
    setEditMode(false);
  };

  let pre_title = "";
  if (loading) {
    pre_title = "טוען: ";
  } else if (!exists) {
    pre_title = "יצירת עמוד: ";
  } else if (editMode) {
    pre_title = "עריכה: ";
  }

  if (loading) {
    return (
      <>
        <div className="sub-header">
          <h1>{pre_title + normalized_title}</h1>
        </div>
        <div className="editor-container">
          <Loading />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="sub-header">
          <h1>{pre_title + normalized_title}</h1>
        </div>
        <div className="editor-container">
          {editMode ? (
            <>
              <div className="markdown-top-bar">
                {modifiedMarkdown && modifiedMarkdown !== markdown ? (
                  <button
                    className="link-look"
                    onClick={() => onSave(markdown)}
                  >
                    שמירה <span className="material-icons md-18">save</span>
                  </button>
                ) : undefined}
                {exists ? (
                  <button
                    className="link-look cancel"
                    onClick={() => onCancel()}
                  >
                    ביטול <span className="material-icons md-18">cancel</span>
                  </button>
                ) : undefined}
              </div>
              <MarkdownEditor initMarkdown={markdown} onChange={onChange} />
            </>
          ) : (
            <>
              <div className="markdown-top-bar">
                <Link to={`/ws/history/${urlTitle(title)}`}>
                  היסטוריה <span className="material-icons md-18">history</span>
                </Link>
                <button className="link-look" onClick={() => setEditMode(true)}>
                  עריכה <span className="material-icons md-18">edit</span>
                </button>
              </div>
              <MarkdownViewer markdown={markdown} />
            </>
          )}
        </div>
      </>
    );
  }
}

function HardcodedMarkdown(props) {
  useEffect(() => {
    document.title = `${props.title} | קהילה.טק`;
  });

  return (
    <>
      <div className="sub-header">
        <h1>{props.title}</h1>
      </div>
      <div className="editor-container">
        <MarkdownViewer markdown={props.markdown} />
      </div>
    </>
  );
}

function TopBar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const linkClassName = showMenu ? "menu-shown" : "menu-hidden";

  return (
    <header>
      <a className="main-link" href="/">
        קהילה.טק
      </a>
      <button className="clickable-menu" onClick={toggleMenu}>
        <span className="material-icons md-24">menu</span>
      </button>
      <div className="menu">
        <div className={linkClassName}>
          <a href="/wiki/קורסים">קורסים</a>
          <a href="/wiki/ויקי">ויקי</a>
          <a href="/about">אודות</a>
          <a href="/search">
            <span className="mobile-only">חיפוש </span>
            <span className="material-icons">search</span>
          </a>
        </div>
      </div>
    </header>
  );
}

function BottomBar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const linkClassName = showMenu ? "menu-shown" : "menu-hidden";

  return (
    <>
      <footer>
        <span>קהילה.טק - קהילה טכנולוגית</span>
        <div className="legal-menu">
          <div className={linkClassName}>
            <a href="/legal/a11y">הצהרת נגישות</a>
            <a href="/legal/terms">תנאי שימוש</a>
            <a href="/legal/privacy">מדיניות פרטיות</a>
          </div>
          <button className="clickable-menu" onClick={toggleMenu}>
            מידע משפטי{" "}
            <span className="material-icons md-18">arrow_drop_up</span>
          </button>
        </div>
      </footer>
    </>
  );
}

function App() {
  return (
    <>
      <TopBar />
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <HardcodedMarkdown title={main_title} markdown={main_markdown} />
            }
          />
          <Route path="/wiki/:title" element={<WikiPage />} />
          <Route path="/wiki" element={<Navigate to="/wiki/ויקי" />} />

          <Route path="/ws/latest" element={<LatestWikiChanges />} />
          <Route path="/ws/all" element={<PagesList type="all" />} />
          <Route path="/ws/missing" element={<PagesList type="missing" />} />
          <Route path="/ws/orphan" element={<PagesList type="orphan" />} />
          <Route path="/ws/history/:title" element={<PageHistory />} />
          <Route path="/search" element={<Search />} />

          <Route
            path="/about"
            element={
              <HardcodedMarkdown
                title={about_title}
                markdown={about_markdown}
              />
            }
          />
          <Route
            path="/legal/terms"
            element={
              <HardcodedMarkdown
                title={terms_title}
                markdown={terms_markdown}
              />
            }
          />
          <Route
            path="/legal/privacy"
            element={
              <HardcodedMarkdown
                title={privacy_title}
                markdown={privacy_markdown}
              />
            }
          />
          <Route
            path="/legal/a11y"
            element={
              <HardcodedMarkdown title={a11y_title} markdown={a11y_markdown} />
            }
          />
          <Route path="/internal-edit" element={<InternalEditor />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <BottomBar />
      <CookieConsent buttonText="מובן">
        <span className="content">
          אתר זה משתמש בעוגיות על מנת לשפר את חווית המשתמש.
        </span>
      </CookieConsent>
    </>
  );
}

export default App;
