import "./Markdown.css";

import React from "react";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import AutoLinkPlugin from "./lexical/AutoLinkPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./lexical/ToolbarPlugin";
import CodeHighlightPlugin from "./lexical/CodeHighlightPlugin";
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS,
} from "@lexical/markdown";
import theme from "./lexical/theme";
import nodes from "./lexical/nodes";
import { YOUTUBE_NODE_TRANSFORMER } from "./lexical/YouTubeTransformer";

const transformers = [...TRANSFORMERS, YOUTUBE_NODE_TRANSFORMER];

function onError(error) {
  console.error(error);
}

export default function MarkdownEditor({ initMarkdown, onChange }) {
  const innerOnChange = (editorState) => {
    editorState.read(() => onChange($convertToMarkdownString(transformers)));
  };

  const initialConfig = {
    editable: true,
    theme,
    onError,
    nodes,
    editorState: () => $convertFromMarkdownString(initMarkdown, transformers),
  };

  return (
    <>
      <LexicalComposer initialConfig={initialConfig} className="editor">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={
              <div className="editor-placeholder">
                הערך ריק, אולי תיצרו אותו?
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <HistoryPlugin />
          <MarkdownShortcutPlugin transformers={transformers} />
          <OnChangePlugin onChange={(o) => innerOnChange(o)} />
        </div>
      </LexicalComposer>
    </>
  );
}
