import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import axios from "axios";
import WikiLink from "./WikiLink";

const TYPES_CONFIG = {
  all: {
    title: "כל העמודים",
    explanation: "רשימת כל העמודים בויקי:",
  },
  missing: {
    title: "עמודים חסרים",
    explanation:
      "עמודים אשר יש אליהם קישור, אך הם לא קיימים. עזרו לנו וצרו אותם!",
  },
  orphan: {
    title: "עמודים יתומים",
    explanation:
      "עמודים אשר אף עמוד אינו מקשר אליהם. עזרו לנו וקשרו אליהם מעמודים קשורים!",
  },
};

export default function AllWikiPages(props) {
  const [pages, setPages] = useState();

  const url = props.type;
  const config = TYPES_CONFIG[url];

  useEffect(() => {
    document.title = `${config.title} | קהילה.טק`;
    axios
      .get(`/api/page/${url}`)
      .then((res) => {
        setPages(res.data);
      })
      .catch((err) => {
        // TODO: show error
        console.log(`Unable to fetch latest changes: ${err}`);
      });
  }, [url, config]);

  if (pages === undefined) {
    return <Loading />;
  }

  return (
    <>
      <div className="sub-header">
        <h1>{config.title}</h1>
      </div>

      <div className="editor-container">
        <div className="flat-container">
          <p>{config.explanation}</p>
          <ul>
            {pages.map((e, i) => (
              <li key={i}>
                <WikiLink title={e} />
              </li>
            ))}
            {pages.length === 0 ? (
              <li>
                <b>הרשימה ריקה</b>
              </li>
            ) : undefined}
          </ul>
        </div>
      </div>
    </>
  );
}
