import "./PageHistory.css";

import ReactDiffViewer from "react-diff-viewer-continued";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import axios from "axios";
import { encodeTitle } from "./Title.js";
import WikiLink from "./WikiLink.js";

export default function PageHistory() {
  const { title } = useParams();
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    document.title = `היסטוריית עמוד | קהילה.טק`;
    axios
      .get(`/api/page/history/${encodeTitle(title)}`)
      .then((res) => {
        setChanges(res.data);
      })
      .catch((err) => {
        // TODO: show error
        console.log(`Unable to fetch latest changes: ${err}`);
      });
  }, [title]);

  if (changes.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <div className="sub-header">
        <h1>שינויים אחרונים</h1>
      </div>

      <p className="history-subtitle">
        שינויים אחרונים לעמוד <WikiLink title={title} />
      </p>

      {changes.map((e, i) => {
        const prev = i + 1 >= changes.length ? "" : changes[i + 1].markdown;
        return (
          <div key={i}>
            <div className="change-date">{e.modified}</div>
            <ReactDiffViewer
              showDiffOnly={false}
              oldValue={prev}
              newValue={e.markdown}
              styles={{
                line: {
                  wordBreak: "break-all",
                },
              }}
              splitView
            />
          </div>
        );
      })}
    </>
  );
}
